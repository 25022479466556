<template>
    <Dialog modal header="Trabajos baremo" :style="{width: '100%', maxWidth: '1200px', height: 'auto', maxHeight: '90vh'}">   
        <div class="trabajos-baremo-container">
            <div class="form-group mb-4">
                <label for="espe">Seleccione especialidad</label>
                <select id="espe" v-model="especialidadSelec" class="form-control">
                    <option value="">Seleccione especialidad</option>
                    <option v-for="especialidad in especialidades" :key="especialidad.idespecialidad" :value="especialidad.idespecialidad">
                    {{ especialidad.nombre }}
                    </option>
                </select>
            </div>
            <div class="table-container">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th>Código</th>
                            <th>Nombre</th>
                            <th>Precio trabajo</th>
                            <th>Precio operario</th>
                            <th>Precio libre</th>
                            <th>Solo Oper.</th>
                            <th>App.</th>
                            <th>Cantidad fija</th>
                            <th>Tipo</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><input v-model="codigoNuevo" class="form-control" type="text"></td>
                            <td><input v-model="nombreNuevo" class="form-control" type="text"></td>
                            <td><input class="form-control" v-model="prtrabajoselec"  type="number"></td>
                            <td><input class="form-control" v-model="properarioselec"  type="number"></td>
                            <td><input v-model="libreselec" :true-value="'SI'" :false-value="''" type="checkbox"></td>
                            <td><input v-model="solooper" :true-value="'operario'" :false-value="''" type="checkbox"></td>
                            <td><input type="checkbox" v-model="appselec" :true-value="1" :false-value="0"></td>
                            <td><input class="form-control" v-model="cantidadselec" type="number"></td>
                            <td>
                            <select style="width: 80px;" class="form-control" v-model="tiposelec">
                                <option value="">---</option>
                                <option value="MAT">MAT</option>
                                <option value="MO">MO</option>
                                <option value="DES">DES</option>
                            </select>
                            </td>
                            <td><button class="btn btn-sm btn-primary" @click="anadirTrabajo">Añadir Trabajo</button></td>
                        </tr>
                        <tr v-for="trabajo in trabajos" :key="trabajo.idtrabajo">
                            <td>{{ trabajo.codigo }}</td>
                            <td>{{ trabajo.nombre }}</td>
                            <td><input type="text" class="form-control" v-model="trabajo.precio"></td>
                            <td><input type="text" class="form-control" v-model="trabajo.precio_operario"></td>
                            <td><input type="checkbox" v-model="trabajo.libre_trabajo" :true-value="'SI'" :false-value="''"></td>
                            <td><input type="checkbox" v-model="trabajo.destinatario" :true-value="'operario'" :false-value="''"></td>
                            <td><input type="checkbox" v-model="trabajo.app" :true-value="1" :false-value="0"></td>
                            <td><input type="text" class="form-control" v-model="trabajo.cantidad"></td>
                            <td>
                            <select v-model="trabajo.tipo" class="form-control">
                                <option value="">---</option>
                                <option value="MAT">MAT</option>
                                <option value="MO">MO</option>
                                <option value="DES">DES</option>
                            </select>
                            </td>
                            <td>
                            <div class="btn-group">
                                <button @click="guardarDatos(trabajo)" title="Guardar datos" class="btn btn-sm btn-success"><i class="fas fa-save"  ></i></button>
                                <button @click="eliminarTrabajo(trabajo)" title="Eliminar trabajo" class="btn btn-sm btn-danger"><i class="fas fa-trash-alt"></i></button>
                            </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </Dialog>
  </template>
  
  <script>
  import { PwgsApi } from '../../../../services/PwgsApi';
  import Dialog from 'primevue/dialog';
  
  export default {
    props: ['baremo', 'compania'],
    components: {
      Dialog,
    },
    data() {
        return {
            appselec:0,
            properarioselec: '',
            prtrabajoselec: '',
            cantidadselec: '',
            tiposelec: '',
            libreselec: false,
            solooper: false,
            especialidadSelec: '',
            especialidades: [],
            trabajos: [],
            nombreNuevo: '',
            codigoNuevo: '',
        };
    },
    mounted() {
      this.obtenerEspecialidades();
    },
    methods: {    
        async obtenerEspecialidades() {
            const api = new PwgsApi();
            const resp = await api.get('companias/' + this.compania.idcompañia + '/especialidades');
            this.especialidades = resp.datos;
        },
        async obtenerTrabajos() {
            const api = new PwgsApi();
            const resp = await api.get('baremos-pwgs/' + this.compania.idcompañia + '?id_baremo=' + this.baremo.id_baremo + '&id_especialidad_compania=' + this.especialidadSelec);
            this.trabajos = Array.isArray(resp.datos) ? resp.datos : [resp.datos];
            console.log('entra', this.trabajos);
        },
        async anadirTrabajo() {
            const api = new PwgsApi();
            if (this.especialidadSelec === "") { 
            alert('Debes seleccionar una especialidad');
            return;
            }
            if (this.codigoNuevo === '' || this.nombreNuevo === '') {
            alert('Debes añadir código y nombre');
            return;
            }
            var body = {id_baremo: this.baremo.id_baremo, codigo: this.codigoNuevo, nombre: this.nombreNuevo, precio: this.prtrabajoselec, 
                precio_operario: this.properarioselec, libre_trabajo: this.libreselec, destinatario: this.solooper, app:this.appselec,
                cantidad: this.cantidadselec, tipo: this.tiposelec, id_especialidad_compania:this.especialidadSelec};

            await api.post('baremos-pwgs/' + this.compania.idcompañia + '/trabajo',body);
            this.obtenerTrabajos();
        },
        async guardarDatos(trabajo) {
            const api = new PwgsApi();
            var body = {id_baremo: this.baremo.id_baremo, codigo: trabajo.codigo, nombre: trabajo.nombre, precio: trabajo.precio, 
                precio_operario: trabajo.precio_operario, libre_trabajo: trabajo.libre_trabajo, destinatario: trabajo.destinatario,
                app: trabajo.app, cantidad: trabajo.cantidad, tipo: trabajo.tipo, id_especialidad_compania:this.especialidadSelec};
            await api.put('baremos-pwgs/' + this.compania.idcompañia + '/trabajo/' + trabajo.idtrabajo,body);
            this.$toast.add({ severity: 'success', summary: 'Realizado', detail: 'Trabajo modificado correctamente', life: 2000 });
        },
        async eliminarTrabajo(trabajo) {
            const api = new PwgsApi();
            await api.delete('baremos-pwgs/' + this.compania.idcompañia + '/trabajo/' + trabajo.idtrabajo);
            this.obtenerTrabajos();
        },
    },
    watch: {
      especialidadSelec(value) {
        this.trabajos = value === '' ? [] : this.obtenerTrabajos();
      }
    }
  };
  </script>
  
  <style scoped>
  .trabajos-baremo-container {
    width: 100%;
    overflow-x: auto;
    padding-bottom: 1rem;
  }
  
  .table-container {
    min-width: 1000px;
  }
  
  .table {
    width: 100%;
  }
  
  td {
    vertical-align: middle;
  }
  
  @media (max-width: 768px) {
    .form-group {
      margin-bottom: 1rem;
    }
  
    .table-container {
      overflow-x: auto;
    }
  
    .table th,
    .table td {
      white-space: nowrap;
    }
  }
  </style>